import React from 'react';
import { Box, Link, Typography, makeStyles } from '@material-ui/core';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { LottieLoadable } from 'components/LottiePlayer';
import animationData from './errorAnimation.json';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(4, 2, 8),
  },
  bullet: {
    color: theme.palette.grey[800],
    margin: theme.spacing(1, 0, 0),
  },
  bulletsWrap: {
    width: 'fit-content',
    margin: theme.spacing(1, 'auto'),
  },
  text: {
    margin: theme.spacing(4, 'auto', 3),
    maxWidth: theme.spacing(40),
  },
}));

function ErrorPage() {
  const classes = useStyles();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Header />
      <Box width={1} textAlign="center" className={classes.pageWrap}>
        <LottieLoadable
          defaultOptions={defaultOptions}
          height={340}
          width={340}
          isStopped={false}
          isPaused={false}
        />
        <Typography className={classes.text}>
          However, here are a few things that can get you back on the right path...
        </Typography>
        <Box textAlign="left" className={classes.bulletsWrap}>
          <Typography className={classes.bullet}>
            • Go back to the <Link href="/">homepage</Link>
          </Typography>
          <Typography className={classes.bullet}>
            • <Link href="/buy-with-rex">Buy</Link> your dream home
          </Typography>
          <Typography className={classes.bullet}>
            • <Link href="/sell-with-rex">Sell</Link> your house with REX
          </Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default ErrorPage;
